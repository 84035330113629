import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { GatsbyPage, IArticle } from "src/types"
import Layout from "src/layout"
import { Section } from "src/components/styled"
import { COLORS } from "src/constants"
import { useCart, getKey } from "src/components/cart"
import { Content, Breadcrumbs, StepTitle, Button, Article, Separator, Row, ShopTitle } from "src/components/shop"
import functions, { handleError } from "src/helpers/functions"
import { formatMoney } from "src/helpers/text"

const CouponInput = styled.input`
  border: 1px solid ${COLORS.main};
  padding: 5px 8px;
  width: 250px;
  min-width: 110px;
  color: ${COLORS.main};
  &::placeholder {
    color: ${COLORS.main};
  }
  &[readonly] {
    color: ${COLORS.dark};
  }
`
const ApplyButton = styled.button`
  color: ${COLORS.main};
  font-weight: bold;
  margin-left: 10px;
`

const CartPage: GatsbyPage = () => {
  const { empty, bookings, presents, coupon, add, setCoupon, getDiscount, getTotal } = useCart()
  const [couponCode, setCouponCode] = useState("")
  const [loading, setLoading] = useState(false)

  const handleQuantity = (article: IArticle, delta: number) => () => {
    if (article.quantity === 1 && delta === -1) {
      if (!confirm("Voulez-vous vraiment supprimer cet article ?")) {
        return
      }
    }
    add(article, delta)
  }

  const handleDiscount = () => {
    if (loading) {
      return
    }
    if (coupon) {
      setCoupon(null)
      setCouponCode("")
    } else if (couponCode) {
      setLoading(true)
      functions
        .get("coupon?code=" + couponCode)
        .then(setCoupon)
        .catch(handleError)
        .then(() => {
          setLoading(false)
        })
    }
  }

  return (
    <Layout title="Panier" noindex>
      <ShopTitle />
      <Section>
        {empty ? (
          <Content>
            <p>Votre panier est vide</p>
          </Content>
        ) : (
          <Content>
            <Breadcrumbs active="panier" />
            <StepTitle>Mon panier</StepTitle>
            {bookings?.map((article) => (
              <Article key={getKey(article)} article={article} onQuantity={handleQuantity} />
            ))}
            {presents?.map((article) => (
              <Article key={getKey(article)} article={article} onQuantity={handleQuantity} />
            ))}
            <Separator />
            <Row>
              <CouponInput
                type="text"
                value={coupon ? coupon.code : couponCode}
                placeholder="Code cadeau ou réduction"
                onChange={({ currentTarget }) => setCouponCode(currentTarget.value.trim().toUpperCase())}
                readOnly={Boolean(coupon)}
              />
              <ApplyButton onClick={handleDiscount}>
                {loading ? "vérification..." : coupon ? "retirer" : "appliquer"}
              </ApplyButton>
              <div>{getDiscount()}</div>
            </Row>
            <Row>
              <div>Total du panier</div>
              <div>{formatMoney(getTotal())}</div>
            </Row>
            <Button as={Link} to="/commande/">
              Commander
            </Button>
            <div>Vous pouvez aussi continuer vos achats en utilisant le menu.</div>
          </Content>
        )}
      </Section>
    </Layout>
  )
}

export default CartPage
